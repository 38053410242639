import { Component, Input } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { NgIf } from "@angular/common";

@Component({
  selector: "app-default-input",
  templateUrl: "./default-input.component.html",
  styleUrls: ["./default-input.component.scss"],
  standalone: true,
  imports: [ReactiveFormsModule, NgIf],
})
export class DefaultInputComponent {
  @Input() text = "";
  @Input() placeholder = "";
  @Input() input: FormControl = new FormControl();
  @Input() required: boolean = true;
}
