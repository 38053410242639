import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { Tree, TreeNode } from "@gul-si/commons";
import { PlusButtonComponent } from "../buttons/plus-button/plus-button.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MinusButtonComponent } from "../buttons/minus-button/minus-button.component";
import { NgFor, NgIf } from "@angular/common";
import { UtilService } from "../../../services/utils.service";

@Component({
  selector: "app-tree",
  templateUrl: "./tree.component.html",
  styleUrls: ["./tree.component.scss"],
  standalone: true,
  imports: [
    NgFor,
    MinusButtonComponent,
    NgIf,
    FontAwesomeModule,
    PlusButtonComponent,
  ],
})
export class TreeComponent {
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  @Input() tree: Tree | undefined;
  @Output() addSubNodeEvent = new EventEmitter<TreeNode>();
  @Output() deleteNodeEvent = new EventEmitter<TreeNode>();
  @Output() selectNodeEvent = new EventEmitter<TreeNode>();
  @Output() moveNode = new EventEmitter<TreeNode>();
  openAndDisplayedNode: string[] = [];

  constructor(private utilService: UtilService) {}

  toggleOpenNode(node: TreeNode) {
    const id = node._id;
    const found = this.openAndDisplayedNode.find((openNodeId) => {
      return openNodeId === id;
    });
    if (!found) this.openAndDisplayedNode.push(id);
    else
      this.openAndDisplayedNode.splice(
        this.openAndDisplayedNode.indexOf(id),
        1,
      );
    this.selectNodeEvent.emit(node);
  }

  isOpen(id: string) {
    return !this.openAndDisplayedNode.find((openNodeId) => {
      return openNodeId === id;
    });
  }

  deleteNode(node: TreeNode) {
    this.deleteNodeEvent.emit(node);
  }

  addSubNode(node: TreeNode | undefined) {
    this.addSubNodeEvent.emit(node);
  }

  moveUpNode(node: TreeNode | undefined, parent: TreeNode | undefined) {
    if (node && parent) {
      const index = parent.childs.indexOf(node);
      this.utilService.ArrayMoveUp(parent.childs, index);
      this.moveNode.emit(parent);
    }
  }

  moveDownNode(node: TreeNode | undefined, parent: TreeNode | undefined) {
    if (node && parent) {
      const index = parent.childs.indexOf(node);
      this.utilService.ArrayMoveDown(parent.childs, index);
      this.moveNode.emit(parent);
    }
  }
}
