import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Contact } from "@gul-si/commons";
import { CtaButtonComponent } from "../buttons/cta-button/cta-button.component";
import { NgIf } from "@angular/common";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { UserAddressFormComponent } from "../form/user-address-form/user-address-form.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faEnvelope,
  faPhone,
  faHouse,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-customer-picker",
  templateUrl: "./customer-picker.component.html",
  styleUrls: ["./customer-picker.component.scss"],
  standalone: true,
  imports: [NgIf, CtaButtonComponent, MatDialogModule, FontAwesomeModule],
})
export class CustomerPickerComponent {
  faEnvelope = faEnvelope;
  faPhone = faPhone;
  faHouse = faHouse;
  faUser = faUser;

  @Input() disabled = true;
  @Input() customer: Contact = new Contact();

  @Output() emitSave = new EventEmitter();

  constructor(public dialog: MatDialog) {}

  editBilling() {
    if (this.disabled) return;
    const dialogRef = this.dialog.open(UserAddressFormComponent, {
      data: {
        object: this.customer,
      },
      panelClass: "custom-dialog",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.emitSave.emit(this.customer.fromObject(result));
      }
    });
  }

  isComplete() {
    return Contact.validation([this.customer]).length > 0;
  }
}
