<div class="article-list">
  <div class="article" *ngFor="let line of articles">
    <div class="detail" [routerLink]="['/produit', line?.product?._id]">
      <!-- Top-line -->
      <div class="top-line">
        <div class="line.picture">
          <img
            *ngIf="!hasPicture(line.product)"
            class="picture"
            [routerLink]="['/produit', line.product._id]"
            src="{{ 'public/no-image.png' }}"
            alt="Placeholder image"
          />
          <img
            *ngIf="hasPicture(line.product)"
            class="picture"
            [routerLink]="['/produit', line.product._id]"
            src="{{ line.product.images[0].url }}"
            alt="Placeholder image"
          />
        </div>
        <div class="description">
          <!-- <p class="reference">Ref: {{line?.product?.ean}}</p> -->
          <h3 class="name" [routerLink]="['/produit', line?.product?._id]">
            {{ line?.product?.name }}
          </h3>
        </div>
      </div>
      <!-- Bottom line -->
      <div class="bottom-line">
        <app-plus-minus-qte
          *ngIf="!disabled"
          class="quantity"
          [quantity]="line.qte"
          (minusTrigger)="
            minusTrigger({ product: line.product, offer: line.offer, qte: 1 })
          "
          (plusTrigger)="
            plusTrigger({ product: line.product, offer: line.offer, qte: 1 })
          "
        >
        </app-plus-minus-qte>
        <h4 *ngIf="disabled">Quantitée : {{ line?.qte }}</h4>
      </div>
    </div>
  </div>
</div>
