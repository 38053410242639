<app-default-input
  [placeholder]="'Rechercher'"
  [input]="searchControl"
  [required]="false"
></app-default-input>
<div
  class=""
  infinite-scroll
  [infiniteScrollDistance]="service.scrollDistance"
  [infiniteScrollUpDistance]="service.scrollUpDistance"
  [infiniteScrollThrottle]="service.throttle"
  (scrolled)="service.next()"
>
  <ng-container *ngFor="let item of service.items">
    <ng-container *ngTemplateOutlet="template; context: { content: item }">
    </ng-container>
  </ng-container>
</div>
