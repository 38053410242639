import { Component, Input, OnChanges } from "@angular/core";
import { concat, remove } from "lodash";
import { FilterBuilder, Page, Product } from "@gul-si/commons";
import { NgIf, NgFor } from "@angular/common";
import { InfiniteScrollDirective } from "ngx-infinite-scroll";
import { NotificationService } from "../../../services/notification.service";
import { SimpleRestService } from "../../../services/simple-rest.service";
import { ProductCardComponent } from "../product-card/product-card.component";

@Component({
  selector: "app-result-list",
  templateUrl: "./result-list.component.html",
  styleUrls: ["./result-list.component.scss"],
  standalone: true,
  imports: [InfiniteScrollDirective, NgIf, NgFor, ProductCardComponent],
})
export class ResultListComponent implements OnChanges {
  @Input() client: SimpleRestService | undefined;
  @Input() isSearchOnly: boolean = false;
  @Input() filter: FilterBuilder;

  array: Product[] = [];
  page = 0;
  size = 10;
  throttle = 10;
  total = -1;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  modalOpen = false;
  userInfo: any;

  constructor(protected notification: NotificationService) {
    this.filter = new FilterBuilder();
  }

  ngOnChanges(change: any) {
    if (change.filter) {
      this.page = 0;
      if (this.filter) {
        if (this.client === undefined) {
          this.notification.error("ERROR", "No client to reach server");
          return;
        }
        this.client
          .getFiltered(this.filter.build() as any, this.page, this.size)
          .subscribe(
            (next: Page<Product>) => {
              this.array = next.data;
              this.total = next.length;
            },
            (error: any) => {
              this.notification.error("ERROR filters", error);
            },
          );
      }
    }
  }

  onScrollDown(ev?: any) {
    if (this.total > 0 && this.page + 1 * this.size < this.total) {
      this.page += 1;
      if (this.client === undefined) {
        this.notification.error("ERROR", "No client to reach server");
        return;
      }
      this.client
        .getFiltered(this.filter.build(), this.page, this.size)
        .subscribe(
          (next: { data: any }) => {
            this.array = concat(this.array, next.data);
          },
          (error: any) => {
            this.notification.error("ERROR", error);
          },
        );
    }
  }

  create(): void {
    if (this.client === undefined) {
      this.notification.error("ERROR", "No client to reach server");
      return;
    }
    this.client.create().subscribe(
      (next: Page<Product>) => {
        this.array.reverse();
        const createdEntity = next.data.pop();
        if (createdEntity !== undefined) {
          this.array.push(createdEntity);
          this.array.reverse();
        }
        this.notification.succes("OK", "Produit cree");
      },
      (error: any) => {
        this.notification.error("ERROR", error);
      },
    );
  }

  delete(id: string): void {
    if (this.client === undefined) {
      this.notification.error("ERROR", "No client to reach server");
      return;
    }
    this.client.delete(id).subscribe(
      () => {
        remove(this.array, (e) => e._id === id);
        this.notification.succes("OK", "Produit supprime");
      },
      (error: any) => {
        this.notification.error("ERROR", error);
      },
    );
  }
}
