import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { FormBuilder, FormControl, ReactiveFormsModule } from "@angular/forms";
import {
  CartSlot,
  FilterBuilder,
  Product,
  Image,
  Page,
  TreeNode,
  Tree,
} from "@gul-si/commons";
import { faHome, faUpload } from "@fortawesome/free-solid-svg-icons";
import { ProductService } from "../../services/product.rest.service";
import { NotificationService } from "../../services/notification.service";
import { NodeService, TreeService } from "../../services/tree.rest.service";
import { BasicFormComponent } from "../../component/material/form/basic-form/basic-form.component";
import { NgFor, NgIf } from "@angular/common";
import { ArticleListComponent } from "../../component/material/article-list/article-list.component";
import { InputModule } from "../../component/material/inputs/input.module";
import { ButtonsModule } from "../../component/material/buttons/buttons.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { UploadDocumentComponent } from "../../component/material/form/upload-document/upload-document.component";
import { MatDialog } from "@angular/material/dialog";
import { BreadcrumbComponent } from "../../component/material/breadcrumb/breadcrumb.component";
import { isEmpty } from "lodash";
import { SelectComponent } from "../../component/material/select/select.component";

@Component({
  selector: "app-product-edit",
  templateUrl: "./product-edit.component.html",
  styleUrls: ["./product-edit.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    ArticleListComponent,
    InputModule,
    ReactiveFormsModule,
    ButtonsModule,
    FontAwesomeModule,
    SelectComponent,
    BreadcrumbComponent,
    RouterLink,
  ],
})
export class ProductEditComponent
  extends BasicFormComponent<Product>
  implements OnInit
{
  faUpload = faUpload;
  faHome = faHome;

  public productId: string = "";
  categories: any[] = [];
  attributes: any[] = [];
  productSearch = new FormControl("");
  searchResult: any[] = [];
  path: TreeNode[] = [];
  tree: Tree = new Tree();

  constructor(
    private catalogue: ProductService,
    private activatedRoute: ActivatedRoute,
    private notification: NotificationService,
    public nodeService: NodeService,
    public treeService: TreeService,
    private route: Router,
    protected fb: FormBuilder,
    public dialog: MatDialog,
  ) {
    super({ object: new Product() }, fb, null as any);
  }

  override ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      (params) => {
        this.productId = params["productId"];
      },
      (error) => {
        this.notification.error("ERREUR", error);
      },
    );
    this.treeService.get(0, 10).subscribe(
      (res) => {
        //TODO clean this shit
        this.tree = new Tree().fromObject(res.data[0]);
      },
      (error) => {
        this.notification.error("ERROR", error);
      },
    );
    this.catalogue.getById(this.productId).subscribe((o) => {
      const product = o.data.pop();
      this.givenObject = product;
      if (!isEmpty(this.givenObject.associatedNode))
        this.nodeService
          .path(this.givenObject.associatedNode)
          .subscribe((o: Page<TreeNode>) => {
            o.data.splice(0, 1);
            this.path = o.data;
          });
      super.ngOnInit();
    });
    this.productSearch.valueChanges.subscribe((change) => {
      if (change && change.length >= 3) {
        const filter = new FilterBuilder()
          .fieldEquals("name", change, true)
          .build();
        this.catalogue.getFiltered(filter, 0, 10).subscribe(
          (next) => {
            this.searchResult = next.data;
          },
          (error) => {
            this.notification.error("ERROR filters", error);
          },
        );
      }
    });
  }

  submit(): any {
    this.validateForm();
    if (this.object) {
      this.object.articles = this.objectAsForm.controls.articles.value;
      this.object.offers = this.objectAsForm.controls.offers.value;
      this.object.articles = this.object.articles.map((p) => {
        {
          return p;
        }
      });
      this.catalogue.update(this.productId, this.object).subscribe(
        (res) => {
          this.notification.succes("OK", "Produit sauvegardé");
          this.route.navigate(["/catalogue", "arborescence"]);
        },
        (error) => {
          this.notification.error("ERREUR", error);
        },
      );
    }
  }

  delete(): void {
    this.catalogue.delete(this.productId).subscribe(
      (res) => {
        this.notification.succes("OK", "Produit supprimé");
        this.route.navigate(["/catalogue"]);
      },
      (error) => {
        this.notification.error("ERREUR", error);
      },
    );
  }

  editPicture(path: any, filename: any) {
    const dialogRef = this.dialog.open(UploadDocumentComponent, {
      data: {
        object: new Image(0, filename),
      },
      panelClass: "custom-dialog",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const img = document.getElementById("image" + 0);
        this.objectAsForm?.controls?.images?.controls[0]?.controls?.url?.setValue(
          result.url,
        );
        if (this.objectAsForm.controls.images.value.length === 0)
          this.objectAsForm.controls.images.value.push(result);
        else this.objectAsForm.controls.images.value[0] = result;
        setTimeout(() => {
          const value = result.url + "?t=" + new Date().getTime();
          if (img) img.setAttribute("src", value);
        }, 1000);
      }
    });
  }
  addToBundle(item: Partial<CartSlot>) {
    if (item.product) {
      const index = this.objectAsForm.controls.articles.value.findIndex(
        (p: { product: { _id: string | undefined } }) =>
          item.product && p?.product?._id === item.product._id,
      );
      if (index !== -1) {
        this.objectAsForm.controls.articles.value[index].qte =
          this.objectAsForm.controls.articles.value[index].qte + 1;
      } else {
        this.objectAsForm.controls.articles.value.push({
          product: item.product,
          productId: item.product._id,
          qte: 1,
        });
      }
    }
  }

  removeFromBundle(item: Partial<CartSlot>) {
    if (item.product) {
      const index = this.objectAsForm.controls.articles.value.findIndex(
        (p: { product: { _id: string | undefined } }) =>
          item.product && p.product._id === item.product._id,
      );
      if (this.objectAsForm.controls.articles.value[index].qte === 1) {
        this.objectAsForm.controls.articles.value.splice(index, 1);
      } else {
        this.objectAsForm.controls.articles.value[index].qte =
          this.objectAsForm.controls.articles.value[index].qte - 1;
      }
    }
  }

  changeOfferVisibility(_id: any, isVisible: any) {
    const index = this.objectAsForm.controls.offers.value.findIndex(
      (p: { _id: any }) => p._id === _id,
    );
    this.objectAsForm.controls.offers.controls[
      index
    ].controls.isVisible.setValue(isVisible);
  }

  getArticles() {
    const res = this.objectAsForm;
    return res;
  }

  associateNode(node: any) {
    // to Implement
    if (this.givenObject.associatedNode && this.givenObject._id)
      this.catalogue
        .setAssociatedNode(
          [node.id] as unknown as string[],
          this.givenObject._id,
        )
        .subscribe(() => {
          this.objectAsForm.controls.associatedNode.value = node.id;
          this.nodeService.path(node.id).subscribe((o: Page<TreeNode>) => {
            o.data.splice(0, 1);
            this.path = o.data;
          });
        });
  }

  leaves() {
    let leaves: any[] = [];
    if (this.tree !== undefined) {
      this.tree.visit((node) => {
        if (node.childs.length === 0) {
          leaves.push(node);
        }
      });
    }
    leaves = leaves.map((leaf: { _id: any; label: any }) => {
      return { id: leaf._id, name: leaf.label };
    });
    return leaves.sort((a: { name: string }, b: { name: any }) =>
      a.name.localeCompare(b.name),
    );
  }
}
