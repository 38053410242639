import { Component, Input, OnChanges } from "@angular/core";
import { CURRENCY, OfferType, Price } from "@gul-si/commons";
import { NgIf, DecimalPipe } from "@angular/common";

@Component({
  selector: "app-price",
  templateUrl: "./price.component.html",
  styleUrls: ["./price.component.scss"],
  standalone: true,
  imports: [NgIf, DecimalPipe],
})
export class PriceComponent implements OnChanges {
  @Input() price: Price;
  @Input() percentPromotion = 0;
  @Input() type: OfferType = OfferType.rent;
  currency: CURRENCY = CURRENCY.EUR;
  referencePrice: number = 0;
  computedPrice: number = 0;

  constructor() {
    this.price = new Price();
  }

  ngOnChanges(): void {
    if (this.price === undefined) this.price = new Price();
    this.price = new Price().fromObject(this.price);
    this.currency = this.price?.currency;
    this.referencePrice = new Price().fromObject(this.price).ht;
    this.computedPrice = new Price()
      .fromObject(this.price)
      .mul(1 - this.percentPromotion).ht;
  }
}
