import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Offer, OfferType, Product } from "@gul-si/commons";
import { AddToCartButtonComponent } from "../buttons/add-to-cart-button/add-to-cart-button.component";
import { PriceComponent } from "../price/price.component";
import { NgFor, NgIf } from "@angular/common";
import { RouterLink } from "@angular/router";
import { ButtonsModule } from "../buttons/buttons.module";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-product-card",
  templateUrl: "./product-card.component.html",
  styleUrls: ["./product-card.component.scss"],
  standalone: true,
  imports: [
    RouterLink,
    NgFor,
    NgIf,
    PriceComponent,
    ButtonsModule,
    AddToCartButtonComponent,
  ],
})
export class ProductCardComponent implements OnInit, OnChanges {
  @Input() product: Product;
  @Input() withPicture = true;
  selectedOffer: Offer;

  constructor() {
    this.product = new Product();
    this.product.offers = [new Offer()];
    this.selectedOffer = this.product.offers[0];
    this.selectedOffer._id = "";
  }
  ngOnInit(): void {
    this.product = new Product().fromObject(this.product);
    this.selectedOffer = this.product.getOffer(
      environment.keycloakConfig.realm,
      OfferType.rent,
    );
  }

  ngOnChanges(): void {
    this.product = new Product().fromObject(this.product);
    this.selectedOffer = this.product.getOffer(
      environment.keycloakConfig.realm,
      OfferType.rent,
    );
  }
  selectOffer(offer: Offer) {
    this.selectedOffer = offer;
  }

  hasPicture() {
    if (this.product.images.length > 0) return true;
    return false;
  }
}
