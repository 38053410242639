<div class="card">
  <div class="wrapper">
    <div *ngIf="withPicture" class="picture">
      <img
        *ngIf="!hasPicture()"
        class="picture"
        [routerLink]="['/produit', product._id]"
        src="{{ 'public/no-image.png' }}"
        alt="Placeholder image"
      />
      <img
        *ngIf="hasPicture()"
        class="picture"
        [routerLink]="['/produit', product._id]"
        src="{{ product.images[0].url }}"
        alt="Placeholder image"
      />
    </div>
    <h2 class="name" [routerLink]="['/produit', product._id]">
      {{ product.name }}
    </h2>
  </div>
  <div class="tabs">
    <ng-container *ngFor="let offer of product.offers">
      <div
        *ngIf="offer.isVisible"
        class="tab"
        (click)="selectOffer(offer)"
        [class.selected]="selectedOffer._id === offer?._id"
      >
        <p>{{ offer.type === "rent" ? "Location" : "Achat" }}</p>
      </div>
    </ng-container>
  </div>
  <ng-container *ngFor="let offer of product.offers">
    <div
      class="recap"
      *ngIf="selectedOffer?._id === offer._id && offer.isVisible"
    >
      <div class="row">
        <app-price
          [routerLink]="['/produit', product._id]"
          [price]="offer.price"
          [type]="offer.type"
        ></app-price>
        <app-plus-minus-qte [quantity]="0"></app-plus-minus-qte>
      </div>
    </div>
  </ng-container>
</div>
