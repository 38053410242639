import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CartSlot, Contact, OfferType, Order, Page } from "@gul-si/commons";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { SimpleRestService } from "./simple-rest.service";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root",
})
export class OrderService extends SimpleRestService {
  constructor(
    private notification: NotificationService,
    public override http: HttpClient,
  ) {
    super(http, "order", ["oms", "admin"]);
  }

  save(order: Order) {
    if (order._id) this.update(order._id, order).subscribe((page) => {});
  }

  add(order: Order, cartSlot: CartSlot): void {
    if (!order) {
      this.notification.succes(
        "INFO",
        "vous devez etre authentifier pour modifier une commande",
      );
      return;
    }
    order.clientOrder.add(cartSlot);
    if (order._id)
      this.update(order._id, order).subscribe(
        (o) => {
          order = order.fromObject(o.data.pop());
          this.notification.succes("OK", "produit ajouté au panier");
        },
        (error) => {
          this.notification.error("ERROR", error);
        },
      );
  }

  remove(order: Order, cartSlot: CartSlot): boolean {
    if (!order) {
      this.notification.succes(
        "INFO",
        "vous devez etre authentifier pour modifier une commande",
      );
      return false;
    }
    order.clientOrder.remove(cartSlot);
    if (order._id)
      this.update(order._id, order).subscribe(
        (o) => {
          this.notification.succes("OK", "produit supprimé de la commande");
          return order.fromObject(o.data.pop());
        },
        (error) => {
          this.notification.error("ERROR", error);
        },
      );
    return true;
  }

  setCustomer(order: Order, customer: Contact): void {
    order.customer = customer;
    order.clientOrder.customer = customer;
    if (order._id)
      this.update(order._id, order).subscribe(
        (event) => {
          this.notification.succes("OK", "Adresse de facturation sauvegardée");
        },
        (error) => {
          this.notification.error("ERROR", error);
        },
      );
  }

  setStartDate(order: Order, event: any) {
    const startDate = {
      date: new Date(event.date),
      slot: event.slot,
    };
    order.clientOrder.articles.map((e) => {
      e.startDate = startDate;
    });
    order.clientOrder.endDate = startDate;
    if (order._id)
      this.update(order._id, order).subscribe(() => {
        return;
      });
  }

  setEndDate(order: Order, event: any) {
    const endDate = {
      date: new Date(event.date),
      slot: event.slot,
    };
    order.clientOrder.articles.map((e) => {
      if (e.offer.type === OfferType.rent) {
        e.endDate = endDate;
      }
    });
    order.clientOrder.endDate = endDate;
    if (order._id)
      this.update(order._id, order).subscribe((event) => {
        return;
      });
  }

  async createQuote(orderId: string | undefined): Promise<void> {
    await this.http.post(`${this.baseUrl}/${orderId}/quote`, {}).subscribe(
      (event) => {
        this.notification.succes("OK", "Devis généré avec succes");
      },
      (error) => {
        this.notification.error("ERROR", error);
      },
    );
  }

  getQuoteUrl(orderId: string | undefined): string {
    return `${this.baseUrl}/${orderId}/quote`;
  }

  downloadQuote(orderId: string): Observable<Blob> {
    return this.http
      .get(`${this.baseUrl}/${orderId}/quote`, { responseType: "blob" })
      .pipe(
        map((res: Blob) => {
          return new Blob([res], { type: "application/pdf" });
        }),
      );
  }

  nextStep(orderId: string | undefined): Observable<Page<Order>> {
    return this.http.post<Page<Order>>(
      `${this.baseUrl}/${orderId}/nextStep`,
      [],
    );
  }

  previousStep(orderId: string | undefined): Observable<Page<Order>> {
    return this.http.post<Page<Order>>(
      `${this.baseUrl}/${orderId}/previousStep`,
      [],
    );
  }

  createOrder(creatorId: string, order: Order) {
    return this.http.post<Page<Order>>(
      `${this.baseUrl}?user=${creatorId}`,
      order,
    );
  }
}
