import { Component } from "@angular/core";
import { BasicFormComponent } from "../basic-form/basic-form.component";
import { Promotion } from "@gul-si/commons";
import { CtaButtonComponent } from "../../buttons/cta-button/cta-button.component";
import { NgIf } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MinusButtonComponent } from "../../buttons/minus-button/minus-button.component";

@Component({
  selector: "app-promotion-form",
  templateUrl: "./promotion-form.component.html",
  styleUrls: ["./promotion-form.component.scss"],
  standalone: true,
  imports: [
    MinusButtonComponent,
    MatDialogModule,
    ReactiveFormsModule,
    NgIf,
    CtaButtonComponent,
  ],
})
export class PromotionFormComponent extends BasicFormComponent<Promotion> {}
