<pdf-viewer
  [src]="orderService.getQuoteUrl(order._id)"
  [render-text]="true"
  [original-size]="true"
  class="pdf"
></pdf-viewer>
<div class="download">
  <app-cta-button
    [text]="'Télecharger'"
    (click)="downloadQuote()"
  ></app-cta-button>
</div>
