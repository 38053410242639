import { Component } from "@angular/core";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
  selector: "app-add-to-cart-button",
  templateUrl: "./add-to-cart-button.component.html",
  styleUrls: [
    "./add-to-cart-button.component.scss",
    "../button.component.scss",
  ],
  standalone: true,
  imports: [FontAwesomeModule],
})
export class AddToCartButtonComponent {
  faCartShopping = faCartShopping;
}
