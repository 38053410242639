import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { Scrolable } from "./scrolable";
import { NgFor, NgTemplateOutlet } from "@angular/common";
import { InfiniteScrollDirective } from "ngx-infinite-scroll";
import { NotificationService } from "../../../services/notification.service";
import { FormControl } from "@angular/forms";
import { FilterBuilder } from "@gul-si/commons";
import { DefaultInputComponent } from "../inputs/default-input/default-input.component";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
  standalone: true,
  imports: [
    InfiniteScrollDirective,
    NgFor,
    NgTemplateOutlet,
    DefaultInputComponent,
  ],
})
export class ListComponent implements OnInit {
  @Input() service!: Scrolable;
  @Input() template!: TemplateRef<any>;
  searchControl: FormControl;
  constructor(private notification: NotificationService) {
    this.searchControl = new FormControl("");
    this.searchControl.valueChanges.subscribe((searchWord) => {
      if (searchWord.length > 1) {
        this.service
          .getFiltered(
            new FilterBuilder().fieldEquals("search", searchWord).build(),
          )
          .subscribe((res) => {
            this.service.items = res.data;
            this.service.total = res.length;
          });
      } else {
        this.service.get().subscribe((next) => {
          if (this.service) {
            this.service.items = next.data;
            this.service.total = next.length;
          }
        });
      }
    });
  }

  ngOnInit(): void {
    if (this.service)
      this.service
        .get(this.service.page, this.service.size)
        .subscribe((next) => {
          if (this.service) {
            this.service.items = next.data;
            this.service.total = next.length;
          }
        });
  }
}
