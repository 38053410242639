import { environment } from "../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Filter, Page, Product } from "@gul-si/commons";
import { Scrolable } from "./scrolable.interface";

export class SimpleRestService implements Scrolable {
  //TODO replace all any by template T
  public http: HttpClient;
  public baseUrl: string;
  public objectName: string;

  constructor(http: HttpClient, objectName: string, path: string[] = []) {
    this.http = http;
    this.baseUrl = environment.backApi + "/";
    this.objectName = objectName;
    if (path?.length > 0) this.baseUrl = this.baseUrl + path.join("/") + "/";
    this.baseUrl = this.baseUrl + this.objectName;
  }

  next(): void {
    throw new Error("Method not implemented.");
  }

  items: any[] = [];
  total = -1;
  page = 0;
  size = 10;
  throttle = 10;
  scrollDistance = 1;
  scrollUpDistance = 2;

  new(): any {
    return {};
  }

  get(page = 0, limit = 10): Observable<Page<any>> {
    return this.http.get<Page<any>>(
      `${this.baseUrl}?size=${limit}&page=${page}`,
    );
  }

  update(id: string, object: any): Observable<Page<any>> {
    return this.http.put<Page<any>>(`${this.baseUrl}/${id}`, [object]);
  }

  put(object: any): Observable<Page<any>> {
    return this.http.put<Page<any>>(`${this.baseUrl}`, [object]);
  }

  updateField(
    id: string,
    field: string[],
    value: any,
    object: any = [],
  ): Observable<Page<any>> {
    field.push(value);
    return this.http.put<Page<any>>(
      `${this.baseUrl}/${id}/${field.join("/")}`,
      object,
    );
  }

  deleteField(
    id: string,
    field: string[],
    value: any,
    object: any = [],
  ): Observable<Page<any>> {
    field.push(value);
    return this.http.delete<Page<any>>(
      `${this.baseUrl}/${id}/${field.join("/")}`,
      { body: object },
    );
  }

  uploadImage(
    id: string,
    index: number,
    object: any,
  ): Observable<Page<Product>> {
    return this.http.put<Page<Product>>(
      `${this.baseUrl}/${id}/picture/${index}`,
      object,
    );
  }

  create(): Observable<Page<any>> {
    return this.http.post<Page<any>>(`${this.baseUrl}`, [this.new()]);
  }

  post(object: any): Observable<Page<any>> {
    return this.http.post<Page<any>>(`${this.baseUrl}`, object);
  }

  delete(id: string) {
    return this.http.delete<any>(`${this.baseUrl}/${id}`);
  }

  getById(id: string) {
    return this.http.get<Page<any>>(`${this.baseUrl}/${id}`);
  }

  getByField(field: string[], value: any): Observable<Page<any>> {
    field.push(value);
    return this.http.get<Page<any>>(`${this.baseUrl}/${field.join("/")}`);
  }

  getFiltered(
    filter: Filter,
    page?: number,
    limit?: number,
  ): Observable<Page<any>> {
    return new Observable();
  }
}
