import { Component, OnInit } from "@angular/core";
import { NotificationService } from "../../services/notification.service";
import { NgClass, NgFor, NgIf } from "@angular/common";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
  imports: [NgFor, NgClass, NgIf],
  standalone: true,
})
export class NotificationComponent implements OnInit {
  constructor(public notification: NotificationService) {}

  ngOnInit(): void {}
}
