<div class="wrapper">
  <button
    class="squared"
    [ngClass]="{
      'border-primary': isOn === false,
      'border-dark3': isOn === true,
    }"
    (click)="switch(false)"
  >
    {{ leftLabel }}
  </button>
  <button
    class="squared"
    [ngClass]="{
      'border-primary': isOn === true,
      'border-dark3': isOn === false,
    }"
    (click)="switch(true)"
  >
    {{ rightLabel }}
  </button>
</div>
