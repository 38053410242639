import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ListComponent } from "./list.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

@NgModule({
  exports: [ListComponent],
  imports: [CommonModule, InfiniteScrollModule, ListComponent],
})
export class ListModule {}
