import { Component } from "@angular/core";
import { RouterLink } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { OrderListComponent } from "../../component/material/order-list/order-list.component";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { DecimalPipe, NgFor } from "@angular/common";

@Component({
  selector: "app-order-dashboard",
  templateUrl: "./order-dashboard.component.html",
  styleUrls: ["./order-dashboard.component.scss"],
  standalone: true,
  imports: [
    RouterLink,
    FontAwesomeModule,
    OrderListComponent,
    DecimalPipe,
    NgFor,
  ],
})
export class OrderDashboardComponent {
  faHome = faHome;
  constructor() {}
}
