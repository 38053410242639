<div class="header">
  <h3>Information de Facturation</h3>
  <app-minus-button
    id="cancel"
    class="close"
    [mat-dialog-close]
  ></app-minus-button>
</div>
<mat-dialog-content>
  <div class="form" [formGroup]="objectAsForm">
    <div class="identity">
      <div class="input-wrapper">
        <h5>Code</h5>
        <input
          id="code"
          class="effect"
          type="text"
          formControlName="code"
          required
          placeholder="MONCODE"
        />
        <span class="focus-border"></span>
        <p *ngIf="objectAsForm?.controls?.code?.errors?.['required']">
          *Obligatoire
        </p>
      </div>
      <div class="input-wrapper">
        <h5>Pourcentage</h5>
        <input
          id="lastname"
          class="effect"
          type="text"
          formControlName="percent"
          required
          placeholder="0"
        />
        <span class="focus-border"></span>
        <p *ngIf="objectAsForm?.controls?.percent?.errors?.['required']">
          *Obligatoire
        </p>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <app-cta-button
    id="save"
    mat-button
    [text]="'Sauvegarder'"
    (click)="validateForm()"
    cdkFocusInitial
  ></app-cta-button>
</mat-dialog-actions>
