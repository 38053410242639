<div class="input-wrapper">
  <h5>{{ text }}</h5>
  <input
    id="code"
    class="effect"
    type="text"
    [formControl]="input"
    required
    placeholder="{{ placeholder }}"
  />
  <span class="focus-border"></span>
  <p *ngIf="input?.errors?.['required'] && required">*Obligatoire</p>
</div>
