<div style="display: flex; flex-direction: row">
  <div class="button" [routerLink]="['/']">
    <fa-icon [icon]="faHome"></fa-icon>
  </div>
  <h4>Catalogue</h4>
</div>
<div class="wrapper">
  <div class="tree-panel">
    <div class="tree" *ngIf="isTreeOpen">
      <div class="root">
        <h3 (click)="selectAllProduct()">Tout les produits</h3>
      </div>
      <div class="root">
        <h3 (click)="selectNode(tree.root)">produits sans catégorie</h3>
      </div>
      <app-tree
        [tree]="tree"
        (addSubNodeEvent)="addSubNode($event)"
        (deleteNodeEvent)="deleteNode($event)"
        (selectNodeEvent)="selectNode($event)"
        (moveNode)="saveNode($event)"
      ></app-tree>
    </div>
    <div
      id="toggle-tree"
      (click)="toggleTree()"
      [ngClass]="{ 'tree-open': isTreeOpen }"
      class="toggle-tree"
    >
      <fa-icon *ngIf="isTreeOpen" [icon]="faChevronLeft"></fa-icon>
      <fa-icon *ngIf="!isTreeOpen" [icon]="faChevronRight"></fa-icon>
    </div>
  </div>
  <div class="main-panel">
    <div class="actions-panel">
      <app-cta-button
        (click)="create()"
        [text]="'Ajouter un produit'"
      ></app-cta-button>
    </div>
    <!-- categorie classique  -->
    <div
      *ngIf="selected?._id !== tree?.root?._id && selected !== null"
      class="edit-panel"
    >
      <div (click)="editNode()" class="attribute-panel">
        <h4>Edition de la catégorie selectionée</h4>
        <h5 *ngIf="!selected">Aucune catégorie selectionnée</h5>
        <div *ngIf="selected">
          <p>{{ selected.label }}</p>
          <h5>Addtributs associés au produits de cette catégorie</h5>
          <ul>
            <li *ngFor="let attribute of selected?.value">
              {{ attribute.label }}
            </li>
          </ul>
        </div>
      </div>
      <div
        *ngIf="
          selected && (!selected.childs.length || selected.parent === 'root')
        "
        class="product-panel"
      >
        <app-result-list-as-list
          [client]="catalogue"
          [filter]="filter"
          [moveActionTarget]="leaves()"
          (actionOnSelection)="associateNode($event)"
        ></app-result-list-as-list>
      </div>
    </div>

    <!-- produit sans categorie (/ root) -->
    <div *ngIf="selected?._id === tree?.root?._id" class="edit-panel">
      <div
        *ngIf="
          selected && (!selected.childs.length || selected.parent === 'root')
        "
        class="product-panel"
      >
        <app-result-list-as-list
          [client]="catalogue"
          [filter]="filter"
          [moveActionTarget]="leaves()"
          (actionOnSelection)="associateNode($event)"
        ></app-result-list-as-list>
      </div>
    </div>

    <!-- Tout les produits -->
    <div *ngIf="selected === null" class="edit-panel">
      <div (click)="editNode()" class="attribute-panel">
        <h4>Edition de la catégorie commune à tous</h4>
        <div>
          <p>{{ tree.root.label }}</p>
          <h5>Addtributs associés au produits de cette catégorie</h5>
          <ul>
            <li *ngFor="let attribute of tree?.root?.value">
              {{ attribute.label }}
            </li>
          </ul>
        </div>
      </div>

      <div class="product-panel">
        <app-result-list-as-list
          [client]="catalogue"
          [filter]="filter"
          [moveActionTarget]="leaves()"
          (actionOnSelection)="associateNode($event)"
        ></app-result-list-as-list>
      </div>
    </div>
  </div>
</div>
