<mat-dialog-content>
  <div class="form" [formGroup]="objectAsForm">
    <div class="input-wrapper">
      <input
        id="attribute-name"
        class="effect"
        type="text"
        formControlName="label"
        placeholder="Nom de la catégorie"
      />
      <span class="focus-border"></span>
    </div>
    <h5>
      Les attributs crée ici seront partagés à tout les produits plus bas dans
      l'arborescence
    </h5>
    <ng-container formArrayName="value">
      <ng-container
        *ngFor="
          let group of objectAsForm.controls.value.controls;
          let i = index
        "
      >
        <div class="row" [formGroup]="group">
          <app-minus-button
            id="attribute-delete"
            (click)="deleteValue(i)"
          ></app-minus-button>
          <div class="input-wrapper">
            <input
              id="attribute-content"
              class="effect"
              formControlName="label"
              placeholder="ex: Marque, Longueur..."
            />
            <span class="focus-border"></span>
          </div>
          <div class="up-down-button">
            <button id="attribute-up">
              <fa-icon
                [icon]="faChevronUp"
                (click)="moveUp(objectAsForm.controls.value.controls, group)"
              ></fa-icon>
            </button>
            <button id="attribute-down">
              <fa-icon
                [icon]="faChevronDown"
                (click)="moveDown(objectAsForm.controls.value.controls, group)"
              ></fa-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="row">
      <app-plus-button
        id="add-attribute"
        (click)="addValue()"
      ></app-plus-button>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="actions">
  <app-cta-button
    id="save"
    [text]="'Sauvegarder'"
    (click)="validateForm()"
  ></app-cta-button>
  <app-cta-button
    id="cancel"
    mat-dialog-close
    [text]="'Annuler'"
    [primary]="false"
  ></app-cta-button>
</mat-dialog-actions>
