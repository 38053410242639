import { Directive, ElementRef, Input } from "@angular/core";
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";

export function forbiddenEmailValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = nameRe.test(control.value);
    return !forbidden ? { forbiddenEmail: { value: control.value } } : null;
  };
}

@Directive({
  standalone: true,
  selector: "[appEmail]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmailDirective,
      multi: true,
    },
  ],
})
export class EmailDirective {
  @Input("appEmail") forbiddenName = "";

  constructor(private el: ElementRef) {}

  validate(control: AbstractControl): ValidationErrors | null {
    return forbiddenEmailValidator(
      new RegExp(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/),
    )(control);
  }
}
