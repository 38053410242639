import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import * as moment from "moment";
import { SwitchButtonComponent } from "../buttons/switch-button/switch-button.component";

@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"],
  standalone: true,
  imports: [SwitchButtonComponent],
})
export class DatepickerComponent implements OnInit {
  @Input() locale: string = "";
  @Input() date: Date | undefined = new Date();
  @Input() slot: string | undefined = "";
  @Input() disabled = false;

  mormingSlot = "09:00\n  -\n12:00";
  afternoonSlot = "14:00\n  -\n18:00";
  isAfterNoon: boolean = false;
  formatedDate: string = "";

  @Output() emitSelectedDate = new EventEmitter<{
    date: Date | undefined;
    slot: string | undefined;
  }>();

  isBellowNoon: boolean = true;

  selectedDate: any;

  constructor() {
    if (this.slot === undefined) {
      this.slot = "";
    }
    if (this.date === undefined) {
      this.date = new Date();
    }
  }

  ngOnInit() {
    moment.locale(this.locale);
  }

  ngOnChanges() {
    if (this.date) {
      this.formatedDate = new Date(this.date).toISOString().split("T")[0];
    }
    if (this.slot) {
      if (this.slot === this.mormingSlot) {
        this.isAfterNoon = false;
      } else {
        this.isAfterNoon = true;
      }
    }
  }

  selectDate(day: any) {
    if (this.disabled) return;
    this.date = new Date((day.target as HTMLInputElement).value);
    this.formatedDate = this.date.toISOString().split("T")[0];
    this.emitSelectedDate.emit({
      date: this.date,
      slot: this.slot,
    });
  }

  selectSlot(slot: string) {
    if (this.disabled) return;
    this.slot = slot;
    this.emitSelectedDate.emit({
      date: this.date,
      slot: this.slot,
    });
  }
}
