import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Order } from "@gul-si/commons";
import { OrderService } from "../../../../services/order.rest.service";
import { ButtonsModule } from "../../../../component/material/buttons/buttons.module";
import { PdfViewerModule } from "ng2-pdf-viewer";
@Component({
  selector: "app-quote",
  templateUrl: "./quote.component.html",
  styleUrls: ["./quote.component.scss"],
  standalone: true,
  imports: [ButtonsModule, PdfViewerModule],
})
export class QuoteComponent {
  @Input() order: Order;
  @Input() client: Order;
  @Output() nextOrderStep = new EventEmitter();

  constructor(protected orderService: OrderService) {
    this.order = new Order();
    this.client = new Order();
  }

  downloadQuote() {
    const pdfName = "downloaded-file.pdf";
    if (this.order._id)
      this.orderService
        .downloadQuote(this.order._id)
        .subscribe((res: BlobPart) => {
          const blob = new Blob([res], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = pdfName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        });
  }
}
