import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CartSlot, Offer, Product } from "@gul-si/commons";
import { RouterLink } from "@angular/router";
import { NgFor, NgIf } from "@angular/common";
import { ButtonsModule } from "../buttons/buttons.module";
import { PriceComponent } from "../price/price.component";

@Component({
  selector: "app-article-list",
  templateUrl: "./article-list.component.html",
  styleUrls: ["./article-list.component.scss"],
  standalone: true,
  imports: [NgFor, RouterLink, NgIf, ButtonsModule, PriceComponent],
})
export class ArticleListComponent {
  @Input() articles: (
    | { product: Product; productId: string; qte: number; offer?: Offer }
    | CartSlot
  )[] = [];
  @Input() disabled = false;

  @Output() plusTriggerEvent = new EventEmitter<CartSlot>();
  @Output() minusTriggerEvent = new EventEmitter<CartSlot>();

  plusTrigger(item: { product: any; offer: any; qte: number }) {
    new CartSlot().fromObject(item);
    this.plusTriggerEvent.emit(new CartSlot().fromObject(item));
  }

  minusTrigger(item: { product: any; offer: any; qte: number }) {
    this.minusTriggerEvent.emit(new CartSlot().fromObject(item));
  }

  hasPicture(product: Product) {
    if (product.images.length > 0) return true;
    return false;
  }
}
