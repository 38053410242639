import { Component, Input } from "@angular/core";
import { NgIf } from "@angular/common";

@Component({
  selector: "app-cta-button",
  templateUrl: "./cta-button.component.html",
  styleUrls: ["./cta-button.component.scss", "../button.component.scss"],
  standalone: true,
  imports: [NgIf],
})
export class CtaButtonComponent {
  @Input() text: string = "Valider";
  @Input() primary = true;
}
