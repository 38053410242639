import { kebabCase, trim, deburr } from "lodash";
import { Injectable } from "@angular/core";
import { TreeNode } from "@gul-si/commons";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  tokenizeString(string: string): string {
    return kebabCase(deburr(trim(string)));
  }

  pathFromTreeNodeArray(nodes: TreeNode[]): string {
    let path = "";
    nodes.forEach((node) => {
      if (node) path += "/" + node.code;
      else return;
    });
    return path;
  }

  ArrayMoveUp(array: any[], index: number) {
    if (index === 0) return array;
    const tmp = array[index - 1];
    array[index - 1] = array[index];
    array[index] = tmp;
    return array;
  }

  ArrayMoveDown(array: any[], index: number) {
    if (index === array.length - 1) return array;
    const tmp = array[index + 1];
    array[index + 1] = array[index];
    array[index] = tmp;
    return array;
  }
}
