import { Component, Input, Output, EventEmitter } from "@angular/core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgIf } from "@angular/common";

@Component({
  selector: "app-visible-button",
  templateUrl: "./visible-button.component.html",
  styleUrls: ["./visible-button.component.scss", "../button.component.scss"],
  standalone: true,
  imports: [NgIf, FontAwesomeModule],
})
export class VisibleButtonComponent {
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  @Input() isVisible: boolean = false;
  @Input() _id: string = "";
  @Output() toggle = new EventEmitter();

  toggleEvent() {
    this.isVisible = !this.isVisible;
    this.toggle.emit({ _id: this._id, isVisible: this.isVisible });
  }
}
