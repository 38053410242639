import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { cloneDeep } from "lodash";

@Component({
  selector: "app-basic-form",
  templateUrl: "./basic-form.component.html",
  styleUrls: ["./basic-form.component.scss"],
  standalone: true,
})
export class BasicFormComponent<T> implements OnInit {
  givenObject: T;
  object: T | null;
  objectAsForm: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      object: T;
    },
    protected formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<any>,
  ) {
    this.givenObject = data.object;
    this.object = cloneDeep(this.givenObject);
  }

  // att init clone the givenValue and create with it a duplicate withFormControl on every attributes
  // we expect to have an object with all the value set to a value, otherwise it will bug
  ngOnInit() {
    if (!this.givenObject) {
      this.givenObject = {} as T;
    }
    const object = cloneDeep(this.givenObject);
    this.objectAsForm = cloneDeep(object);
    this.objectAsForm = this.objectToForm(this.objectAsForm);
  }

  // after validatation, it returns the fiels form maped as a regular javascript object
  validateForm() {
    const clone = cloneDeep(this.objectAsForm);
    this.object = this.objectFormToObject(clone, this.object);
    if (this.dialogRef) return this.dialogRef.close(this.object);
    else return this.object;
  }

  visit(object: any, fx: (a: any) => void): any {
    for (const [key, value] of Object.entries(object)) {
      if (value instanceof Array) {
        object[key] = this.formBuilder.array(
          value.map((e) => this.formBuilder.group(this.visit(e, fx))),
        );
      } else if (value instanceof Object) {
        object[key] = this.formBuilder.group(this.visit(value, fx));
      } else {
        object[key] = fx(value);
      }
    }
    return object;
  }

  mapVisit(form: any, object: any, fx: (a: any) => void): any {
    if (form.controls !== undefined) form = form.controls;
    for (let [key, value] of Object.entries(form)) {
      if ((value as any)["controls"] !== undefined)
        value = (value as any)["controls"];
      if (value instanceof Array) {
        object[key] = value.map((e) => this.mapVisit(e, {}, fx));
      } else if (
        value instanceof Object &&
        !(value instanceof FormControl) &&
        !(value instanceof Boolean)
      ) {
        object[key] = this.mapVisit(value, {}, fx);
      } else {
        object[key] = fx(value);
      }
    }
    return object;
  }

  objectToForm(object: any) {
    const objectAsForm = this.formBuilder.group(
      this.visit(object, (value: any) => {
        const control = [value, Validators.required];
        return control;
      }),
    );
    return objectAsForm;
  }

  objectFormToObject(objectAsForm: any, dest: any) {
    const object = this.mapVisit(objectAsForm, {}, (arg: any) => {
      const value = arg.value;
      return value;
    });
    return object;
  }
}
