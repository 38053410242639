import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Filter, Page } from "@gul-si/commons";
import { SimpleRestService } from "./simple-rest.service";

@Injectable({
  providedIn: "root",
})
export class ProductService extends SimpleRestService {
  constructor(public override http: HttpClient) {
    super(http, "product", ["catalogue"]);
  }

  override new(associatedNode = ""): any {
    return {
      name: "New",
      description: "Non renseigné",
      attributes: [],
      images: [],
      associatedNode: associatedNode,
      offers: [
        {
          seller: "katharsis",
          type: "rent",
          price: {
            ht: 999,
            TVA: 20,
            currency: "€",
          },
          isVisible: false,
        },
      ],
    };
  }

  queryOrFromFilters(filters: any[]) {
    const grouped = {
      $or: filters?.map((e) => {
        const o = {} as any;
        o[e.code] = e.value;
        return o;
      }),
    };
    return grouped;
  }

  queryAndFromFilters(filters: any[]) {
    const grouped = {
      $and: filters?.map((e) => {
        const o = {} as any;
        o[e.code] = e.value;
        return o;
      }),
    };
    return grouped;
  }

  override getFiltered(
    filter: Filter,
    page = 1,
    limit = 10,
  ): Observable<Page<any>> {
    return this.http.post<Page<any>>(
      `${this.baseUrl}/filtered?size=${limit}&page=${page}`,
      filter,
    );
  }
  //TODO clean
  setAssociatedNode(productIds: string[], value: string) {
    return this.http.post<Page<any>>(
      `${this.baseUrl}/associateNode/${value}`,
      productIds.map((e) => {
        return { _id: e };
      }),
    );
  }

  createAndAssociate(associatedNodeId: string): Observable<Page<any>> {
    return this.http.post<Page<any>>(`${this.baseUrl}`, [
      this.new(associatedNodeId),
    ]);
  }

  updateAssociatedNode(from: string, to: string): Observable<Page<any>> {
    return this.http.put<Page<any>>(
      `${this.baseUrl}/associatedNode/${from}/${to}`,
      [],
    );
  }
}
