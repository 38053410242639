import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SimpleRestService } from "./simple-rest.service";

@Injectable({
  providedIn: "root",
})
export class CategoryService extends SimpleRestService {
  constructor(public override http: HttpClient) {
    super(http, "category", ["catalogue"]);
  }

  override new(): any {
    return { label: "New" };
  }
}
