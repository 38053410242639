import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { Product } from "@gul-si/commons";
import { NgFor, DecimalPipe } from "@angular/common";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
  standalone: true,
  imports: [ReactiveFormsModule, NgFor, DecimalPipe],
})
export class InputComponent {
  @Input() control!: FormControl;
  @Input() placeholder!: string;
  @Input() results!: Product[];
  @Output() selectResultEvent = new EventEmitter<Product>();

  selectResult(item: Product) {
    this.selectResultEvent.emit(item);
    this.results = [];
  }
}
