import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { SimpleRestService } from "./simple-rest.service";

@Injectable({
  providedIn: "root",
})
export class TreeService extends SimpleRestService {
  constructor(override http: HttpClient) {
    super(http, "tree", ["catalogue"]);
  }
}

@Injectable({
  providedIn: "root",
})
export class NodeService extends SimpleRestService {
  constructor(override http: HttpClient) {
    super(http, "node", ["catalogue", "tree"]);
  }

  add(id: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/${id}`, {});
  }

  path(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${id}/path`);
  }
}
