import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgFor } from "@angular/common";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
  standalone: true,
  imports: [ReactiveFormsModule, NgFor],
})
export class SelectComponent {
  @Input() states: any[] = [];

  form = new FormGroup({
    state: new FormControl(this.states[0]),
  });

  @Output() select = new EventEmitter<string>();

  constructor() {
    this.form.valueChanges.subscribe((v) => {
      if (v.state) this.select.emit(v.state);
      return v;
    });
  }
}
