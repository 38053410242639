import { Routes } from "@angular/router";
import { AuthGuard } from "./component/login/auth.guard";
import { CatalogueComponent } from "./page/catalogue/catalogue.component";
import { ProductInventoryComponent } from "./page/product-inventory/product-inventory.component";
import { PromotionComponent } from "./page/promotion/promotion.component";
import { ProductEditComponent } from "./page/product-edit/product-edit.component";
import { OrderDashboardComponent } from "./page/order-dashboard/order-dashboard.component";
import { OrderPageComponent } from "./page/order-edit/order.component";
import { ClientsPageComponent } from "./page/clients/clients.component";
import { ClientEditComponent } from "./page/client-edit/client-edit.component";

export const routes: Routes = [
  { path: "", component: CatalogueComponent },
  {
    path: "arborescence",
    component: ProductInventoryComponent,
    canActivate: [AuthGuard],
    data: { roles: ["office"] },
  },
  {
    path: "promotions",
    component: PromotionComponent,
    canActivate: [AuthGuard],
    data: { roles: ["office"] },
  },
  {
    path: "produit/:productId/edit",
    component: ProductEditComponent,
    canActivate: [AuthGuard],
    data: { roles: ["office"] },
  },
  {
    path: "commandes",
    component: OrderDashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: ["order"] },
  },
  {
    path: "commande/:orderId",
    component: OrderPageComponent,
    canActivate: [AuthGuard],
    data: { roles: ["order"] },
  },
  {
    path: "clients/:userId/edit",
    component: ClientEditComponent,
    canActivate: [AuthGuard],
    data: { roles: ["order"] },
  },
  {
    path: "clients",
    component: ClientsPageComponent,
    canActivate: [AuthGuard],
    data: { roles: ["order"] },
  },
];
