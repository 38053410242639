<div class="wrapper">
  <div class="order-list">
    <div class="order">
      <!-- Delivery and return date-dime -->
      <div class="order-summary">
        <div class="shipping-date border-top">
          <div class="row">
            <h4><fa-icon [icon]="faTruckFast"></fa-icon>Date de livraison</h4>
          </div>
          <div class="row">
            <app-date-picker
              [disabled]="false"
              [locale]="'fr'"
              [date]="order.clientOrder.startDate?.date"
              [slot]="order.clientOrder.startDate?.slot"
              (emitSelectedDate)="orderService.setEndDate(order, $event)"
            ></app-date-picker>
          </div>
          <div *ngIf="order.clientOrder.articles?.[0]?.endDate" class="row">
            <h4><fa-icon [icon]="faTruckFast"></fa-icon>Date de retour</h4>
          </div>
          <div *ngIf="order.clientOrder.articles?.[0]?.endDate" class="row">
            <app-date-picker
              [disabled]="false"
              [locale]="'fr'"
              [date]="order.clientOrder.endDate?.date"
              [slot]="order.clientOrder.endDate?.slot"
              (emitSelectedDate)="orderService.setStartDate(order, $event)"
            ></app-date-picker>
          </div>
        </div>
        <div class="shipping border-top">
          <h3><strong>Livraison</strong></h3>
          <app-customer-picker
            [customer]="order.clientOrder.shipping"
            [disabled]="false"
            (emitSave)="orderService.save(order)"
          ></app-customer-picker>
        </div>
      </div>
      <app-search-bar
        (clickEvent)="orderService.add(order, $event)"
      ></app-search-bar>
      <app-article-list
        [disabled]="false"
        [articles]="order.clientOrder.articles"
        (plusTriggerEvent)="orderService.add(order, $event)"
        (minusTriggerEvent)="orderService.remove(this.order, $event)"
      ></app-article-list>
    </div>
  </div>

  <div class="summary">
    <div class="billing border-top">
      <h3><strong>Facturation</strong></h3>
      <app-customer-picker
        [customer]="order.clientOrder.customer"
        [disabled]="false"
        (emitSave)="orderService.save(order)"
      ></app-customer-picker>
    </div>

    <div class="resume border-top">
      <div class="row">
        <h4>Sous total HT/jours</h4>
        <p>{{ order.clientOrder.dailyPrice().getHT() | number: "1.2-2" }}</p>
      </div>
      <div class="row">
        <h4>
          Réduction code promotion
          {{
            order.clientOrder.promotions?.discountCode?.percent
              ? order.clientOrder.promotions?.discountCode?.percent
              : (0 | number: "1.0-2")
          }}
          %
        </h4>
        <p *ngIf="order?.clientOrder?.promotions?.discountCode">
          -{{
            order.clientOrder.promotions?.discountCode
              ?.apply(order.clientOrder.totalPrice())
              ?.getHT() | number: "1.2-2"
          }}
        </p>
        <p *ngIf="!order?.clientOrder?.promotions?.discountCode">
          -{{ 0 | number: "1.2-2" }}
        </p>
      </div>
      <div class="row">
        <div class="row">
          <app-default-input
            [text]="'Réduction commerciale en %'"
            [input]="commercialDiscountControl"
          ></app-default-input>
        </div>

        <p *ngIf="order?.clientOrder?.promotions?.commercial">
          -{{
            order.clientOrder.promotions?.commercial
              ?.apply(order.clientOrder.totalPrice())
              ?.getHT() | number: "1.2-2"
          }}
        </p>
        <p *ngIf="!order?.clientOrder?.promotions?.commercial">
          -{{ 0 | number: "1.2-2" }}
        </p>
      </div>
      <div class="row">
        <h4>Retrait au dépôt</h4>
        <p>Gratuit</p>
      </div>
    </div>

    <div class="total">
      <div class="row">
        <h4>TVA</h4>
        <h5>{{ order.totalPrice().getTVA() | number: "1.2-2" }}</h5>
      </div>
      <div class="row">
        <h3>Total HT</h3>
        <h4>{{ order.totalPrice().getHT() | number: "1.2-2" }}</h4>
      </div>
      <div class="row">
        <h3>Total TTC</h3>
        <h4>{{ order.totalPrice().getTTC() | number: "1.2-2" }}</h4>
      </div>
      <div class="row">
        <app-cta-button
          class="validate"
          (click)="nextOrderStep.emit()"
          [text]="'Etape suivante'"
        ></app-cta-button>
      </div>
    </div>
  </div>
</div>
