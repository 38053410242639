<div class="wrapper">
  <div id="catalogue" class="item" [routerLink]="['/arborescence']">
    <span class="title" id="tree-redirect"><i class="fa fa-sitemap"></i></span>
    <h5>Catalogue</h5>
  </div>
  <div id="order" class="item" [routerLink]="['/commandes']">
    <span class="title" id="family-redirect"
      ><i class="fa fa-shopping-cart"></i
    ></span>
    <h5>Commandes</h5>
  </div>
  <div class="item" [routerLink]="['/promotions']">
    <span class="title" id="family-redirect"
      ><i class="fa fa-percent"></i
    ></span>
    <h5>Code promotion</h5>
  </div>
  <div class="item" [routerLink]="['/clients']">
    <span class="title" id="family-redirect"><i class="fa fa-avatar"></i></span>
    <h5>Clients</h5>
  </div>
</div>
