import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { faTruckFast } from "@fortawesome/free-solid-svg-icons";
import {
  Order,
  OrderStepName,
  Promotion,
  PromotionType,
} from "@gul-si/commons";
import { AuthGuard } from "../../../../component/login/auth.guard";
import { OrderService } from "../../../../services/order.rest.service";
import { ButtonsModule } from "../../../../component/material/buttons/buttons.module";
import { DecimalPipe, NgIf } from "@angular/common";
import { InputModule } from "../../../../component/material/inputs/input.module";
import { CustomerPickerComponent } from "../../../../component/material/customer-picker/customer-picker.component";
import { ArticleListComponent } from "../../../../component/material/article-list/article-list.component";
import { SearchBarComponent } from "../../../../component/material/search-bar/search-bar.component";
import { DatepickerComponent } from "../../../../component/material/date-picker/date-picker.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
  selector: "app-resume",
  templateUrl: "./resume.component.html",
  styleUrls: ["./resume.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    ButtonsModule,
    DecimalPipe,
    InputModule,
    CustomerPickerComponent,
    ArticleListComponent,
    SearchBarComponent,
    DatepickerComponent,
    FontAwesomeModule,
  ],
})
export class ResumeComponent implements OnInit {
  faTruckFast = faTruckFast;
  OrderStepName = OrderStepName;

  @Input() order: Order;

  @Output() nextOrderStep = new EventEmitter();

  commercialDiscountControl = new FormControl(0);

  constructor(
    protected authService: AuthGuard,
    protected orderService: OrderService,
  ) {
    this.order = new Order();
  }
  ngOnInit(): void {
    if (this?.order?.clientOrder?.promotions?.commercial?.percent)
      this.commercialDiscountControl.setValue(
        this?.order?.clientOrder?.promotions?.commercial?.percent,
      );
    this.commercialDiscountControl.valueChanges.subscribe((v) => {
      if (this?.order?.clientOrder?.promotions?.commercial)
        this.order.clientOrder.promotions.commercial =
          new Promotion().fromObject({
            type: PromotionType.PERCENT_COMMERCIAL,
            code: "VALIDATION_STEP",
            percent: Number(v),
          });
      this.orderService.save(this.order);
    });
  }
}
