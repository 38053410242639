import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Contact, Order } from "@gul-si/commons";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { CustomerPickerComponent } from "../../component/material/customer-picker/customer-picker.component";
import { UserInfoService } from "../../services/user-info.rest.service";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { NotificationService } from "../../services/notification.service";
import { ListComponent } from "../../component/material/list/list.component";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { OrderService } from "../../services/order.rest.service";
import { CtaButtonComponent } from "../../component/material/buttons/cta-button/cta-button.component";

@Component({
  selector: "app-client-edit",
  standalone: true,
  imports: [
    CustomerPickerComponent,
    FontAwesomeModule,
    ListComponent,
    RouterLink,
    FontAwesomeModule,
    CtaButtonComponent,
  ],
  templateUrl: "./client-edit.component.html",
  styleUrl: "./client-edit.component.scss",
})
export class ClientEditComponent {
  faHome = faHome;

  customer: Contact;

  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public userService: UserInfoService,
    public route: Router,
    public notification: NotificationService,
    public orderService: OrderService,
  ) {
    this.customer = new Contact();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.userService.getById(params["userId"]).subscribe(
        (res) => {
          console.log(res);
          this.customer = new Contact().fromObject(res.data.pop());
        },
        (error) => {
          this.notification.error("ERREUR", error);
        },
      );
    });
  }

  onSaveUser(user: Contact) {
    this.userService.put(user).subscribe(
      (res) => {
        return res;
      },
      (error) => {
        this.notification.error("ERREUR", error);
      },
    );
  }

  createOrder() {
    if (Contact.validation([this.customer]).length > 0) {
      this.notification.error(
        "ERROR",
        new Error("Information client invalide"),
      );
      return;
    }
    if (this.customer._id) {
      const order = new Order().fromObject({
        customer: this.customer,
        clientOrder: {
          customer: this.customer,
          shipping: this.customer,
          articles: [],
        },
      });
      this.orderService.createOrder(this.customer._id, order).subscribe(
        (res) => {
          console.log(res);
          this.route.navigate(["/commande", res.data[0]._id]);
          return res;
        },
        (error) => {
          this.notification.error("ERREUR", error);
        },
      );
    }
  }
}
