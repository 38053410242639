import { Component } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { ProductService } from "../../services/product.rest.service";
import { CategoryService } from "../../services/category.rest.service";
import { NodeService, TreeService } from "../../services/tree.rest.service";
import { NotificationService } from "../../services/notification.service";

@Component({
  selector: "app-catalogue",
  templateUrl: "./catalogue.component.html",
  styleUrls: ["./catalogue.component.scss"],
  standalone: true,
  imports: [RouterLink],
})
export class CatalogueComponent {
  selectedNodeCategories: any;

  constructor(
    public catalogue: ProductService,
    public category: CategoryService,
    public tree: TreeService,
    public node: NodeService,
    public notification: NotificationService,
    private activatedRoute: ActivatedRoute,
  ) {}
}
