import { Component, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { faHome, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Promotion } from "@gul-si/commons";
import { RouterLink } from "@angular/router";
import { CtaButtonComponent } from "../../component/material/buttons/cta-button/cta-button.component";
import { IconButtonComponent } from "../../component/material/buttons/icon-button/icon-button.component";
import { PromotionService } from "../../services/promotion.rest.service";
import { PromotionFormComponent } from "../../component/material/form/promotion-form/promotion-form.component";
import { NotificationService } from "../../services/notification.service";
import { ListModule } from "../../component/material/list/list.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
  selector: "app-promotion",
  templateUrl: "./promotion.component.html",
  styleUrls: ["./promotion.component.scss"],
  standalone: true,
  imports: [
    RouterLink,
    CtaButtonComponent,
    ListModule,
    IconButtonComponent,
    FontAwesomeModule,
  ],
})
export class PromotionComponent {
  faPencil = faPencil;
  faTrash = faTrash;
  faHome = faHome;

  @ViewChild("promotionCard") promotionCard!: TemplateRef<any>;

  constructor(
    public promotionService: PromotionService,
    public dialog: MatDialog,
    public notification: NotificationService,
  ) {}

  edit(promotion: Promotion) {
    const dialogRef = this.dialog.open(PromotionFormComponent, {
      data: {
        object: promotion,
      },
      panelClass: "custom-dialog",
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          this.promotionService.save(result);
        }
      },
      (error) => {
        this.notification.error("ERROR", error);
      },
    );
  }
}
