import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import {
  APP_INITIALIZER,
  Inject,
  Injectable,
  PLATFORM_ID,
  Provider,
} from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { from, Observable, switchMap } from "rxjs";
import { environment } from "../../environments/environment";
import { isPlatformBrowser } from "@angular/common";

@Injectable({ providedIn: "root" })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private keycloakService: KeycloakService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!isPlatformBrowser(this.platformId)) {
      return next.handle(req);
    }
    return from(this.keycloakService.getToken()).pipe(
      switchMap((token) => {
        if (token) {
          // Clone and add Authorization header
          const authReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
          return next.handle(authReq);
        } else {
          // No token, forward the request unmodified
          return next.handle(req);
        }
      }),
    );
  }

  isLoggedIn(): boolean {
    return this.keycloakService.isLoggedIn();
  }

  async login(): Promise<void> {
    await this.keycloakService.login();
  }

  async logout(): Promise<void> {
    await this.keycloakService.logout();
  }

  getUserProfile(): any {
    return this.keycloakService.getKeycloakInstance().tokenParsed;
  }
}

export function initializeKeycloak(
  keycloak: KeycloakService,
): () => Promise<boolean> {
  if (typeof window === "undefined") {
    return () =>
      new Promise<boolean>((resolve, reject) => {
        setTimeout(() => {
          resolve(false);
        }, 0);
      });
  } else {
    return () => {
      return keycloak.init({
        config: environment.keycloakConfig,
        initOptions: {
          checkLoginIframe: false,
        },
        // Enables Bearer interceptor
        enableBearerInterceptor: true,
        // Prefix for the Bearer token
        bearerPrefix: "Bearer",
        // URLs excluded from Bearer token addition (empty by default)
        //bearerExcludedUrls: []
      });
    };
  }
}

// Provider for Keycloak Bearer Interceptor
export const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};

// Provider for Keycloak Initialization
export const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService],
};
