import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CtaButtonComponent } from "./cta-button/cta-button.component";
import { AddToCartButtonComponent } from "./add-to-cart-button/add-to-cart-button.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SwitchButtonComponent } from "./switch-button/switch-button.component";
import { PlusButtonComponent } from "./plus-button/plus-button.component";
import { MinusButtonComponent } from "./minus-button/minus-button.component";
import { ToggleCtaButtonComponent } from "./toggle-cta-button/toggle-cta-button.component";
import { VisibleButtonComponent } from "./visible-button/visible-button.component";
import { IconButtonComponent } from "./icon-button/icon-button.component";
import { PlusMinusQteComponent } from "./plus-minus-qte/plus-minus-qte.component";

@NgModule({
  exports: [
    CtaButtonComponent,
    AddToCartButtonComponent,
    SwitchButtonComponent,
    PlusButtonComponent,
    MinusButtonComponent,
    ToggleCtaButtonComponent,
    VisibleButtonComponent,
    IconButtonComponent,
    PlusMinusQteComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    CtaButtonComponent,
    AddToCartButtonComponent,
    SwitchButtonComponent,
    PlusButtonComponent,
    MinusButtonComponent,
    ToggleCtaButtonComponent,
    VisibleButtonComponent,
    IconButtonComponent,
    PlusMinusQteComponent,
  ],
})
export class ButtonsModule {}
