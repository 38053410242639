import { Component, Input } from "@angular/core";
import { TreeNode } from "@gul-si/commons";
import { faChevronRight, faHome } from "@fortawesome/free-solid-svg-icons";
import { NgFor } from "@angular/common";
import { RouterLink } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
  standalone: true,
  imports: [FontAwesomeModule, RouterLink, NgFor],
})
export class BreadcrumbComponent {
  faChevronRight = faChevronRight;
  faHome = faHome;
  @Input() path: TreeNode[] = [];

  getPath(node: TreeNode) {
    const index = this.path.findIndex((n) => n.code === node.code);
    let path = "";
    for (let i = 0; i <= index; i++) {
      path = path + "/" + this.path[i].code;
    }
    return path;
  }
}
