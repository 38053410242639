import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgIf } from "@angular/common";

@Component({
  selector: "app-toggle-cta-button",
  templateUrl: "./toggle-cta-button.component.html",
  styleUrls: ["./toggle-cta-button.component.scss"],
  standalone: true,
  imports: [NgIf],
})
export class ToggleCtaButtonComponent {
  @Input() textOff: string = "OFF";
  @Input() textOn: string = "ON";
  isOn: boolean = false;
  @Output() on = new EventEmitter();
  @Output() off = new EventEmitter();

  turnOff() {
    this.isOn = false;
    this.off.emit();
  }

  turnOn() {
    this.isOn = true;
    this.on.emit();
  }
}
