import { NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faCheck,
  faClock,
  faCreditCard,
  faFileInvoice,
  faFileSignature,
  faHandshake,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { OrderStepName } from "@gul-si/commons";
@Component({
  selector: "app-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"],
  standalone: true,
  imports: [NgFor, NgIf, FontAwesomeModule],
})
export class StepperComponent {
  faCheck = faCheck;
  faClock = faClock;
  @Input() steps = [
    {
      name: OrderStepName.pending,
      icon: faClock,
    },
    {
      name: OrderStepName.validate,
      icon: faHandshake,
    },
    {
      name: OrderStepName.shipping,
      icon: faTruck,
    },
    {
      name: OrderStepName.quote,
      icon: faFileSignature,
    },
    {
      name: OrderStepName.payment,
      icon: faCreditCard,
    },
    {
      name: OrderStepName.bill,
      icon: faFileInvoice,
    },
  ];

  @Input() currentStep: OrderStepName | undefined = OrderStepName.payment;

  compareWithCurrentStep(step: { name: OrderStepName }) {
    const stepPosition = this.steps.findIndex((e) => e.name === step.name);
    const currentStepPosition = this.steps.findIndex(
      (e) => e.name === this.currentStep,
    );
    if (this.currentStep === OrderStepName.closed) return -1;
    if (currentStepPosition === -1)
      if (stepPosition === 0) return 0;
      else return 1;
    else return stepPosition - currentStepPosition;
  }
}
