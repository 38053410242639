<div class="wrapper">
  <div class="notification-item">
    <div
      class="notification"
      [ngClass]="{
        danger: message.type === 'error',
        warning: message.type === 'warning',
      }"
      *ngFor="let message of notification.messages"
      (click)="notification.deleteMessageById(message.id)"
    >
      <strong> {{ message.code }} : {{ message.message }} </strong>
    </div>
  </div>
</div>
