<div class="wrapper">
  <input
    ng-disabled="disabled"
    class="column has-text-centered"
    type="date"
    [value]="formatedDate"
    (input)="selectDate($event)"
  />
  <app-switch-button
    [disabled]="disabled"
    [leftLabel]="mormingSlot"
    [rightLabel]="afternoonSlot"
    [isOn]="isAfterNoon"
    (switchChange)="selectSlot($event)"
  ></app-switch-button>
</div>
