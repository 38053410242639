<div class="informations" (click)="editBilling()">
  <div class="container">
    <div class="displayed">
      <span
        ><strong>
          {{ customer.firstName ? customer.firstName : "Prenom" }}</strong
        ></span
      >
      <span>
        <strong>
          {{ customer.lastName ? customer.lastName : "Nom" }}<br /></strong
      ></span>
      <br />
      <span
        >{{
          customer.address.street ? customer.address.street : "Rue de la paix"
        }}<br
      /></span>
      <span
        >{{ customer.address.zipcode ? customer.address.zipcode : "75000" }}
      </span>
      <span
        >{{ customer.address.city ? customer.address.city : "Paris" }}<br
      /></span>
      <span
        >{{ customer.address.country ? customer.address.country : "France"
        }}<br /><br
      /></span>
      <fa-icon [icon]="faEnvelope"></fa-icon
      ><span>
        {{ customer.email ? customer.email : "john.doe@email.com" }} </span
      ><br />
      <fa-icon [icon]="faPhone"></fa-icon
      ><span>
        {{
          customer.cellularNumber ? customer.cellularNumber : "48 15 16 23 42"
        }}</span
      >
    </div>
    <div *ngIf="isComplete()" class="error">
      <strong> Informations incomplètes </strong>
    </div>
  </div>
</div>
<div *ngIf="!disabled && isComplete()" class="row">
  <app-cta-button
    class="edit"
    (click)="editBilling()"
    [text]="'Modifier'"
  ></app-cta-button>
</div>
