import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { CartSlot, FilterBuilder, Offer, Product } from "@gul-si/commons";
import { ProductService } from "../../../services/product.rest.service";
import { NotificationService } from "../../../services/notification.service";
import { DecimalPipe, NgFor, NgIf } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
  selector: "app-search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"],
  standalone: true,
  imports: [NgFor, NgIf, DecimalPipe, FontAwesomeModule, ReactiveFormsModule],
})
export class SearchBarComponent implements OnInit {
  faSearch = faSearch;
  searchForm = new FormControl("");
  @Output() clickEvent = new EventEmitter<any>();
  results: any[] = [];
  focused = false;

  constructor(
    private productService: ProductService,
    private notification: NotificationService,
  ) {}

  ngOnInit() {
    this.searchForm = new FormControl("");
    this.searchForm.valueChanges.subscribe((change) => {
      if (change && change.length >= 3) {
        const filter = new FilterBuilder().fieldEquals("name", change, true);
        this.productService.getFiltered(filter.build(), 0, 10).subscribe(
          (next) => {
            this.results = [];
            next.data.forEach((product) => {
              product.offers.forEach((offer: Partial<any>) => {
                const unwindedProduct = new Product().fromObject(product);
                unwindedProduct.offers = [new Offer().fromObject(offer)];
                this.results.push(unwindedProduct);
              });
            });
          },
          (error) => {
            this.notification.error("ERROR filters", error);
          },
        );
      }
    });
  }

  onKeyDownEvent() {
    this.focused = false;
  }

  onClickEvent(value: Partial<CartSlot>) {
    if (value?.product?.name) {
      this.searchForm.setValue(value?.product?.name);
      this.focused = false;
      if (value.product._id)
        this.productService.getById(value.product._id).subscribe((res) => {
          value.product = new Product().fromObject(res.data.pop());
          this.clickEvent.emit(value);
        });
    }
  }

  onFocusEvent(isFocused: boolean) {
    this.focused = isFocused;
  }
}
