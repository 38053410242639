import { Component, OnInit } from "@angular/core";
import { BasicFormComponent } from "../basic-form/basic-form.component";
import { Attribute, TreeNode } from "@gul-si/commons";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { CtaButtonComponent } from "../../buttons/cta-button/cta-button.component";
import { PlusButtonComponent } from "../../buttons/plus-button/plus-button.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MinusButtonComponent } from "../../buttons/minus-button/minus-button.component";
import { NgFor } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { UtilService } from "../../../../services/utils.service";

@Component({
  selector: "app-tree-node-form",
  templateUrl: "./tree-node-form.component.html",
  styleUrls: ["./tree-node-form.component.scss"],
  standalone: true,
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    NgFor,
    MinusButtonComponent,
    FontAwesomeModule,
    PlusButtonComponent,
    CtaButtonComponent,
  ],
})
export class TreeNodeFormComponent
  extends BasicFormComponent<TreeNode>
  implements OnInit
{
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  addValue() {
    this.objectAsForm.controls.value.push(this.objectToForm(new Attribute()));
  }

  deleteValue(lessonIndex: number) {
    this.objectAsForm.controls.value.removeAt(lessonIndex);
  }

  moveUp(array: any, element: TreeNode) {
    const index = array.indexOf(element);
    new UtilService().ArrayMoveUp(array, index);
  }

  moveDown(array: any, element: TreeNode) {
    const index = array.indexOf(element);
    new UtilService().ArrayMoveDown(array, index);
  }
}
