import { Component } from "@angular/core";
import { BasicFormComponent } from "../basic-form/basic-form.component";
import { Contact } from "@gul-si/commons";
import { CtaButtonComponent } from "../../buttons/cta-button/cta-button.component";
import { NgIf } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { ButtonsModule } from "../../buttons/buttons.module";

@Component({
  selector: "app-user-address-form",
  templateUrl: "./user-address-form.component.html",
  styleUrls: ["./user-address-form.component.scss"],
  standalone: true,
  imports: [
    ButtonsModule,
    ReactiveFormsModule,
    NgIf,
    CtaButtonComponent,
    MatDialogModule,
  ],
})
export class UserAddressFormComponent extends BasicFormComponent<Contact> {}
