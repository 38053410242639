import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SimpleRestService } from "./simple-rest.service";
import { concat } from "lodash";
import { Contact, Filter, Page } from "@gul-si/commons";
import { NotificationService } from "./notification.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserInfoService extends SimpleRestService {
  constructor(
    public override http: HttpClient,
    protected notification: NotificationService,
  ) {
    super(http, "users", ["referential"]);
  }

  override next(ev?: any) {
    console.log(ev);
    if (this.total > 0 && this.page + 1 * this.size < this.total) {
      this.page += 1;
      this.get(this.page, this.size).subscribe(
        (next) => {
          this.items = concat(
            this.items,
            next.data.map((o) => {
              return new Contact().fromObject(o);
            }),
          );
        },
        (error) => {
          this.notification.error("ERROR", error);
        },
      );
    }
  }

  override getFiltered(
    filter: Filter,
    page = 1,
    limit = 50,
  ): Observable<Page<any>> {
    return this.http.post<Page<any>>(
      `${this.baseUrl}/filtered?size=${limit}&page=${page}`,
      filter,
    );
  }
}
