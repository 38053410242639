import { Component } from "@angular/core";
import { Contact } from "@gul-si/commons";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { CustomerPickerComponent } from "../../component/material/customer-picker/customer-picker.component";
import { UserInfoService } from "../../services/user-info.rest.service";
import { Router, RouterLink } from "@angular/router";
import { NotificationService } from "../../services/notification.service";
import { ListComponent } from "../../component/material/list/list.component";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { DefaultInputComponent } from "../../component/material/inputs/default-input/default-input.component";
import { FormControl } from "@angular/forms";
import { CtaButtonComponent } from "../../component/material/buttons/cta-button/cta-button.component";

@Component({
  selector: "app-clients",
  standalone: true,
  imports: [
    CustomerPickerComponent,
    FontAwesomeModule,
    ListComponent,
    RouterLink,
    FontAwesomeModule,
    DefaultInputComponent,
    CtaButtonComponent,
  ],
  templateUrl: "./clients.component.html",
  styleUrl: "./clients.component.scss",
  providers: [],
})
export class ClientsPageComponent {
  faHome = faHome;
  newUserFormControl: FormControl;

  constructor(
    public userService: UserInfoService,
    public notification: NotificationService,
    private route: Router,
  ) {
    this.newUserFormControl = new FormControl("");
  }

  async createUser() {
    await this.userService
      .post([
        new Contact().fromObject({ email: this.newUserFormControl.value }),
      ])
      .subscribe(
        (res) => {
          this.route.navigate(["/clients", res.data[0]._id, "edit"]);
        },
        (error) => {
          this.notification.error("ERROR", error);
        },
      );
  }
}
