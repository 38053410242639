<div style="display: flex; flex-direction: row; align-items: center">
  <div class="button" [routerLink]="['/clients']">
    <fa-icon [icon]="faHome"></fa-icon>
  </div>
  <h4>Clients</h4>
</div>

<app-customer-picker
  [disabled]="false"
  [customer]="customer"
  (emitSave)="onSaveUser($event)"
></app-customer-picker>

<app-cta-button
  [text]="'créer une commande'"
  (click)="createOrder()"
></app-cta-button>
