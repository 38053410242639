import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-plus-minus-qte",
  templateUrl: "./plus-minus-qte.component.html",
  styleUrls: ["./plus-minus-qte.component.scss"],
  standalone: true,
})
export class PlusMinusQteComponent {
  @Input() quantity: number = 0;
  @Output() plusTrigger = new EventEmitter<void>();
  @Output() minusTrigger = new EventEmitter<void>();

  ngOnInit() {
    if (!this.quantity) {
      this.quantity = 0;
    }
  }

  add() {
    this.quantity++;
    this.plusTrigger.emit();
  }
  remove() {
    this.quantity--;
    if (this.quantity < 0) {
      this.quantity = 0;
      return;
    }
    this.minusTrigger.emit();
  }
}
