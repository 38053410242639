import { environment } from "../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { random, remove } from "lodash";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  public baseUrl = environment.backApi;
  public messages: { id: string; type: string; code: any; message: string }[] =
    [];
  private timeout = 3000;

  constructor(private http: HttpClient) {}

  generateId(): string {
    return random(100000, 10000000).toString();
  }

  deleteMessageById(id: string) {
    remove(this.messages, (m) => {
      return m.id === id;
    });
  }

  addNotification(type: string, code: any, message: string) {
    const id = this.generateId();
    this.messages.push({ id: id, type: type, code, message });
    return new Promise((resolve) => {
      setTimeout(resolve, this.timeout);
    }).then(() => {
      this.deleteMessageById(id);
    });
  }

  error(code: any, message: any) {
    this.addNotification(
      "error",
      code,
      JSON.stringify(message?.error?.message),
    );
  }
  succes(code: any, message: string) {
    this.addNotification("success", code, message);
  }
  warn(code: any, message: string) {
    this.addNotification("warning", code, message);
  }
}
