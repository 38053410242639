import { ApplicationConfig, provideZoneChangeDetection } from "@angular/core";
import {
  PreloadAllModules,
  provideRouter,
  withPreloading,
} from "@angular/router";

import { routes } from "./app.routes";
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import {
  KeycloakBearerInterceptorProvider,
  KeycloakInitializerProvider,
} from "./component/login/auth.interceptor";
import { KeycloakService } from "keycloak-angular";

export const appConfig: ApplicationConfig = {
  providers: [
    KeycloakBearerInterceptorProvider,
    KeycloakInitializerProvider,
    KeycloakService,
    provideHttpClient(withInterceptorsFromDi()), // Provides HttpClient with interceptors
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withPreloading(PreloadAllModules),
      // withDebugTracing(),
    ),
  ],
};
